import { useEffect, useState } from 'react';

const useReducedMotion = () => {
  const [reducedMotion, setReducedMotion] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    const reducedMotionHandler = () => setReducedMotion(mediaQuery.matches);

    reducedMotionHandler();

    mediaQuery.addEventListener('change', reducedMotionHandler);

    return () => mediaQuery.removeEventListener('change', reducedMotionHandler);
  }, []);

  return reducedMotion;
};

export default useReducedMotion;
