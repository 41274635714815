const responsiveObj = {
  largeDesktop: {
    breakpoint: { max: 6000, min: 1128 },
    items: 3,
    partialVisibilityGutter: -1,
  },
  middleDesktop: {
    breakpoint: { max: 1128, min: 900 },
    items: 3,
    partialVisibilityGutter: -1,
  },
  largeTablet: {
    breakpoint: { max: 900, min: 768 },
    items: 2,
    partialVisibilityGutter: -1,
  },
  smallTablet: {
    breakpoint: { max: 768, min: 650 },
    items: 2,
    partialVisibilityGutter: -1,
  },
  middleMobile: {
    breakpoint: { max: 650, min: 480 },
    partialVisibilityGutter: -1,
    items: 1,
  },
  smallMobile: {
    breakpoint: { max: 480, min: 10 },
    items: 1,
  },
};

export { responsiveObj as default };
