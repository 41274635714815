const responsiveObj = {
  superLargeDesktop: {
    breakpoint: { max: 6000, min: 992 },
    items: 1,
    partialVisibilityGutter: 130,
  },
  tablet: {
    breakpoint: { max: 991, min: 500 },
    items: 2,
    partialVisibilityGutter: 58,
  },
  smallMobile: {
    breakpoint: { max: 499, min: 200 },
    items: 1,
    partialVisibilityGutter: 58,
  },
};

export { responsiveObj as default };
