const responsiveObj = {
  default: {
    breakpoint: {
      max: 6000,
      min: 0,
    },
    items: 1,
  },
};

export default responsiveObj;
