import React, { FC, useEffect, useRef } from 'react';

import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';
import VideoPlayer from '../VideoPlayer';

import { IVideoModalProperties } from './model';
import './VideoModal.scss';

const VideoModal: FC<IVideoModalProperties> = ({
  isOpen,
  toggleModal,
  link,
  preTitle,
  timeNames,
  closeText,
}) => {
  const videPlayerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleTabKey = (e: KeyboardEvent) => {
      if (!isOpen || e.keyCode !== 9) return;

      const videoFrame = videPlayerRef?.current?.querySelector('iframe');

      if (videoFrame) {
        videoFrame?.focus();
        e.preventDefault();
      }
    };

    document.body.style.overflow = isOpen ? 'hidden' : 'unset';
    document.addEventListener('keydown', handleTabKey);

    return () => {
      document.body.style.overflow = 'unset';
      document.removeEventListener('keydown', handleTabKey);
    };
  }, [isOpen]);

  const stopEvents = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return isOpen ? (
    <div className="video-modal-overlay" role="presentation" onClick={toggleModal}>
      <div className="video-modal" role="presentation" onClick={stopEvents}>
        <div className="video-modal__video-conent" ref={videPlayerRef}>
          <VideoPlayer link={link} preTitle={preTitle} timeNames={timeNames} disableFacade />
        </div>
        <Button variant="link" classes="video-modal__close-btn" onClick={toggleModal}>
          <IconCustom icon="close" />
          {closeText}
        </Button>
      </div>
    </div>
  ) : null;
};
export default VideoModal;
