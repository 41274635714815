import React, { FC, useState, useCallback, useRef } from 'react';

import IconCustom from 'components/common/IconCustom';
import Button from 'components/common/Button';
import Portal from 'components/Portal/Portal';

import { IVideoBlockProperties } from './model';
import VideoModal from './VideoModal';
import './VideoBlock.scss';

const VideoBlock: FC<IVideoBlockProperties> = ({
  link,
  preTitle,
  timeNames,
  name,
  time,
  closeText,
  ariaLabel,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const modalTriggerRef = useRef<HTMLButtonElement>(null);

  const toggleModal = useCallback(() => {
    setIsOpen((prevOpen) => {
      if (prevOpen && modalTriggerRef.current) {
        modalTriggerRef.current.focus();
      }

      return !prevOpen;
    });
  }, [isOpen]);

  return (
    <div className="video-block">
      <Button
        ariaLabel={ariaLabel}
        classes="video-block__play-btn"
        variant="link"
        onClick={toggleModal}
        ref={modalTriggerRef}
      >
        <IconCustom icon="play-circle" />
        {name || time ? (
          <div className="video-block__info">
            <p className="video-block__name">{name}</p>
            <p className="video-block__time">{time}</p>
          </div>
        ) : null}
      </Button>
      <Portal>
        <VideoModal
          closeText={closeText}
          isOpen={isOpen}
          toggleModal={toggleModal}
          link={link}
          preTitle={preTitle}
          timeNames={timeNames}
        />
      </Portal>
    </div>
  );
};
export default VideoBlock;
