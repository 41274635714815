import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import GatsbyImage from 'components/common/GatsbyImage';

import { IBrandHistoryCardProps } from './model';
import './BrandHistoryCard.scss';

const BrandHistoryCard: FC<IBrandHistoryCardProps> = ({ image, imageAlt, year, description }) => (
  <div className="gs-history-card" data-testid="brand-history-card">
    {image?.fallbackUrl ? (
      <GatsbyImage className="gs-history-card__image" fluid={image} alt={imageAlt} />
    ) : null}
    <strong className="gs-history-card__title">{year}</strong>
    <DangerouslySetInnerHtml className="gs-history-card__description" html={description} />
  </div>
);

export default BrandHistoryCard;
